// 
// tablesaw.scss
//

.tablesaw {
    thead {
        background: $table-accent-bg;
        background-image: none;
        border: none;
        th {
            text-shadow: none;
        }
        tr:first-child th {
            border: none;
            font-weight: 500;
            font-family: $font-family-secondary;
        }
    }
    td {
        border-top: $table-border-width solid $table-border-color !important;
    }
}

.tablesaw td,
.tablesaw tbody th {
    font-size: inherit;
    line-height: inherit;
    padding: 10px !important;
}

.tablesaw-stack tbody tr,
.tablesaw tbody tr {
    border-bottom: none;
}

.tablesaw-bar .btn-select.btn-small:after,
.tablesaw-bar .btn-select.btn-micro:after {
    font-size: 8px;
    padding-right: 10px;
}

.tablesaw-swipe .tablesaw-cell-persist {
    box-shadow: none;
    border-color: $gray-100;
}

.tablesaw-enhanced .tablesaw-bar .btn {
    text-shadow: none;
    background-image: none;
    text-transform: none;
    border: 1px solid $gray-700;
    padding: 3px 10px;
    color: $dark;

    &:after {
        display: none;
    }
}

.tablesaw-enhanced {
    .tablesaw-bar {
        .btn {
            &:hover,
            &:focus,
            &:active {
                color: $primary !important;
                background-color: $gray-100;
                outline: none !important;
                box-shadow: none !important;
                background-image: none;
            }

            &.btn-select {
                &:hover {
                    background: $gray-700;
                    color: $gray-300 !important;
                }
            }
        }
    }
}

.tablesaw-columntoggle-popup {
    .btn-group {
        display: block;
    }
}

.tablesaw-swipe {
    .tablesaw-swipe-cellpersist {
        border-right: 2px solid $table-border-color;
    }
}

.tablesaw-sortable-btn {
    cursor: pointer;
}

.tablesaw-advance {
    a {
        &.tablesaw-nav-btn {
            &.left:before {
                border-right: 5px solid $gray-600;
            }
            &.right:before {
                border-left: 5px solid $gray-600;
            }
        }
    }
}