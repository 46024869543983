//
// dropify.scss
//

@font-face {
    font-family: 'dropify';
    src: url("../fonts/dropify.eot");
    src: url("../fonts/dropify.eot#iefix") format("embedded-opentype"), url("../fonts/dropify.woff") format("woff"), url("../fonts/dropify.ttf") format("truetype"), url("../fonts/dropify.svg#dropify") format("svg");
    font-weight: normal;
    font-style: normal;
}

.dropify-wrapper {
    border: 2px dashed rgba($dark, 0.3);
    background: $gray-800;
    border-radius: 6px;
    color: $gray-500;

    &:hover {
        background-image: linear-gradient(-45deg,$gray-700 25%,transparent 25%,transparent 50%,$gray-700 50%,$gray-700 75%,transparent 75%,transparent);
    }

    .dropify-preview {
        background-color: lighten($gray-800,5%);
    }
}