// 
// search-results.scss
//

.search-result-box {
    .tab-content {
        padding: 30px 30px 10px 30px;
        box-shadow: none;
    }
    .search-item {
        padding-bottom: 20px;
        border-bottom: 1px solid lighten($gray-800,3%);
        margin-bottom: 20px;
    }
}